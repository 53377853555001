<template>
    <div class="body-form">
      <Loading v-if="isLoading" />
      <div class="cart-form">
        <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
          {{ name }}
        </h1>
        <div class="form-ctn">
          <div class="content-form">
            <p>ID</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.id"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Full Name</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.full_name"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Invoice Number</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.invoice_number"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Price</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.price"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Total Price</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.total_price"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Program</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.name"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Status</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.status"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Created At</p>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="formData.created_at"
              class="form-input"
              dense
              clearable
            ></v-text-field>
          </div>
          <div class="submit-box" v-if="!isWatch">
            <Button
              name="PUBLISH"
              width="260px"
              @click="onSubmit"
              :disabled="!validData"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import Button from "@/components/Button.vue";
  import moment from "moment";
  
  export default {
    name: "FormTransaction",
    data() {
      return {
        name: "Lihat Transaksi",
        id: 2,
        isLoading: false,
        isWatch: true,
        formData: {
          // id: "",
          // full_name: "",
          // invoice_number: "",
          // price: 0,
          // total_price: 0,
          // program: "",
          // status: "",
          // created_at: "",
        },
      };
    },
    components: {
      Button,
      Loading,
    },
    methods: {
      checkingType() {
        this.id = this.$route.params.id;
        if (!this.id) return;
        this.getTransactionLogDetail();
      },
      async getTransactionLogDetail() {
        this.isLoading = true;
        try {
          const resp = await this.$crm_http.get(
            `/v1/transaction-log/report/${this.id}`
          );
          const data = resp.data.data;
          this.formData = {
            ...data,
            id: data.id,
            status: data.status,
            full_name: data.full_name,
            price: this.$helpers.formatCurrency(data.price),
            total_price: this.$helpers.formatCurrency(data.total_price),
            program: data.name,
            invoice_number: data.invoice_number,
            created_at:moment(data.created_at).format("DD MMM YYYY HH:mm"),
          };
          this.originalStatus = this.form.status;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
    mounted() {
      this.checkingType();
    },
  };
  </script>
  
  <style scoped>
  .body-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafaf9;
    padding: 50px 0;
    min-height: 100vh;
    width: 100vw;
  }
  
  .form-ctn {
    padding: 0 40px;
  }
  
  .cart-form {
    background-color: white;
  
    position: relative;
    width: 50%;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  
  .back-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    color: rgb(198, 198, 198);
  }
  
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  
  .no-space {
    margin-bottom: -20px;
  }
  
  .content-form {
    margin-top: -5px;
  }
  
  .content-form p {
    font-size: 16px;
    margin: 3px 0;
    font-weight: bold;
  }
  
  .berkas-title {
    font-size: 18px;
    margin: 20px 0;
    font-weight: bold;
  }
  
  .spacing-ctn {
    margin-bottom: 30px;
    width: 100%;
  
    background-color: #f5f6fa;
    height: 10px;
  }
  
  .submit-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .criteria-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  
  .location-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  
  .criteria-btn {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin: 10px 0;
    justify-content: flex-end;
  }
  
  @media screen and (max-width: 1100px) {
    .cart-form {
      width: 70%;
    }
  }
  </style>
  